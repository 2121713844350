import { createGlobalStyle } from "styled-components"
import theme from "./theme"
import "../fonts/fonts.css"

export default createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  /*
    Optionally: Define a strong focus indicator for keyboard focus.
    If you choose to skip this step then the browser's default focus
    indicator will be displayed instead.
  */
  .js-focus-visible .focus-visible {}

  /*
    Optionally: Define text selection color and background-color
  */
 ::selection {
    color: ${theme.colors.white};
    background-color: ${theme.colors.accent};
  }

  /*
    https://css-tricks.com/almanac/properties/s/scrollbar/
  */
  /* ::-webkit-scrollbar {
    width: ;
    height: ;
  }
  ::-webkit-scrollbar-track {
    background-color: ;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ;

    &:focus {
      outline: none;
    }
  } */

  /*
    Disable Firefox dotted focus outline
  */
  :-moz-focusring {
    outline: none;
  }

  html {
    color: ${theme.colors.variants.light.text.body};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-tap-highlight-color: transparent;
  }

  body {
    background-color: ${theme.colors.variants.light.background};
    font-family: "GeometricaNarrow", sans-serif;
    margin: 0;
    overflow-x: hidden;

    &.disable-scroll {
      overflow: hidden;
    }
  }

  a {
    color: inherit;
  }

  a,
  button {
    &:focus {
      outline-color: ${theme.colors.accent};
    }
  }

  svg {
    display: block;
  }
`
