import React from "react"
import GlobalStyle from "./GlobalStyle"
import CookiesConsentProxy from "./CookiesConsentProxy"

export default function Layout({ innerRef, children }) {
  return (
    <>
      <GlobalStyle />
      <div ref={innerRef}>{children}</div>
      <CookiesConsentProxy />
    </>
  )
}
